<template>
  <div class="modal" >
    <div class="modal-background"></div>
    <div class="modal-content">
          <slot></slot>
     </div>
    <slot name="modal-close"></slot>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
export default {
  props: {
    customClass: {
      type: String,
      default: '' // is-medium, is-large
    }
  },
  setup (props) {
    const customClass = ref(props.customClass)

    const renderClass = computed(() => {
      return customClass.value
    });

    return {
      renderClass
    }
  }
}
</script>

<style lang="scss" scoped>

</style>